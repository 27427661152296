import React, { useState } from "react";
import Layout from "../../Layout";
import "./style.scss";
import Seo from "../../seo";

export default function UniformPage() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const summer = (
    <div className="uniform__summer">
      <div className="uniform__subheading">Classes Nursery & Prep</div>
      <div className="uniform__table">
        <div className="uniform__table__row uniform__table__row--heading">
          <div className="uniform__table__col1">Girls</div>
          <div className="uniform__table__col2">Boys</div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Frock: Blue & grey one piece
          </div>
          <div className="uniform__table__col2">
            Shirt: Half sleeves blue shirt with grey piping on sleeves and
            school insignia on front pockets.
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1"></div>
          <div className="uniform__table__col2">Shorts: Grey</div>
        </div>
      </div>

      <div className="uniform__subheading">Classes I to VII</div>
      <div className="uniform__table">
        <div className="uniform__table__row uniform__table__row--heading">
          <div className="uniform__table__col1">Girls</div>
          <div className="uniform__table__col2">Boys</div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Shirt: Blue plain half sleeves
          </div>
          <div className="uniform__table__col2">
            Shirt: Half sleeves blue shirt with grey piping on sleeves and
            school insignia on front pockets.
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">Skirt: Grey divided skirt</div>
          <div className="uniform__table__col2">Shorts: Grey</div>
        </div>
      </div>

      <div className="uniform__subheading">Classes VIII to XII</div>
      <div className="uniform__table">
        <div className="uniform__table__row uniform__table__row--heading">
          <div className="uniform__table__col1">Girls</div>
          <div className="uniform__table__col2">Boys</div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Kameez: Blue grey front jacket one piece kameez
          </div>
          <div className="uniform__table__col2">
            Shirt: Half sleeves blue shirt with grey piping on sleeves and
            school insignia on front pockets.
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">Salwar: Grey salwar</div>
          <div className="uniform__table__col2">
            Trousers: Grey with flap over back pocket
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Socks: White with one grey stripe
          </div>
          <div className="uniform__table__col2">
            Socks: white with one grey stripe
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Shoes: Black shoes with laces/ velcro
          </div>
          <div className="uniform__table__col2">
            Shoes: Black shoes with laces/ velcro
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Hair band: Only black hair band/ ribbon or elastic band is
            permitted.
          </div>
          <div className="uniform__table__col2">
            Belt : Grey with white stripes and school insignia
          </div>
        </div>
      </div>
    </div>
  );

  const winter = (
    <div lassName="uniform__winter">
      <div className="uniform__subheading">Classes Nursery to VIII</div>
      <div className="uniform__table">
        <div className="uniform__table__row uniform__table__row--heading">
          <div className="uniform__table__col1">Girls</div>
          <div className="uniform__table__col2">Boys</div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Shirt: Full sleeves blue shirt with school insignia on the front
            pocket
          </div>
          <div className="uniform__table__col2">
            Shirt: Full sleeves blue shirt with school insignia on the front
            pocket
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Trousers: Woolen grey with flap over back pockets
          </div>
          <div className="uniform__table__col2">
            Trousers: Woolen grey with flap over back pockets
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Socks: Grey colour with sky blue stripe
          </div>
          <div className="uniform__table__col2">
            Socks: Grey colour with sky blue stripe
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Belt: Grey with white strips and school insignia
          </div>
          <div className="uniform__table__col2">
            Belt: Grey with white strips and school insignia
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Tie: Alternate strips of navy blue and grey with Chinmaya Vidyalaya
            inscribed
          </div>
          <div className="uniform__table__col2">
            Tie: Alternate strips of navy blue and grey with Chinmaya Vidyalaya
            inscribed
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Sweater: Grey pullover (school pattern) with a red and white stripe
          </div>
          <div className="uniform__table__col2">
            Sweater: Grey pullover (school pattern) with a red and white stripe
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">Blazer: Navy blue</div>
          <div className="uniform__table__col2">Blazer: Navy blue</div>
        </div>
      </div>

      <div className="uniform__subheading">Classes VIII to XII</div>
      <div className="uniform__table">
        <div className="uniform__table__row uniform__table__row--heading">
          <div className="uniform__table__col1">Girls</div>
          <div className="uniform__table__col2">Boys</div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Kameez: Blue grey front jacket one piece kameez
          </div>
          <div className="uniform__table__col2">
            Shirt: Full sleeves blue shirt with school insignia on the front
            pocket
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">Salwar: Grey salwar</div>
          <div className="uniform__table__col2">
            Trousers: Woolen grey with flap over back pockets
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Socks: Grey colour with sky blue stripe
          </div>
          <div className="uniform__table__col2">
            Socks: Grey colour with sky blue stripe
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">
            Sweater: Grey pullover (school pattern) with a red and white stripe
          </div>
          <div className="uniform__table__col2">
            Belt: Grey with white strips and school insignia
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1">Blazer: Navy blue</div>
          <div className="uniform__table__col2">
            Tie: Alternate strips of navy blue and grey with Chinmaya Vidyalaya
            inscribed
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1"></div>
          <div className="uniform__table__col2">
            Sweater: Grey pullover (school pattern) with a red and white stripe
          </div>
        </div>

        <div className="uniform__table__row">
          <div className="uniform__table__col1"></div>
          <div className="uniform__table__col2">Blazer: Navy blue</div>
        </div>
      </div>
    </div>
  );

  let uniformJSX = null;

  if (currentIndex === 0) {
    uniformJSX = summer;
  } else if (currentIndex === 1) {
    uniformJSX = winter;
  }

  return (
    <Layout>
      <Seo
        title="School Uniform | Chinmaya Vidyalaya"
        description="All students must wear the prescribed school uniform to schools on all days including examinations, school tours and excursions."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="uniform">
        <h1 className="heading">School Uniform</h1>
        <p className="uniform__text">
          All the students must wear the prescribed school uniform to schools on
          all days including examinations, school tours and excursions. The
          uniform should be purchased from the authorized school uniform dealer.
          <br />
          <br />
          Parents are requested to ensure their wards wear the proper, neat and
          clean uniform daily, and help instill and inculcate a sense of pride
          and respect for the school uniform.
          <br />
          <br />
          Boys should have neat, short hair. Girls with long hair should keep
          them plaited and wear ribbon/band. Short hair has to be kept in place
          with a head band. Girls are not allowed to have a fringe cut.
        </p>

        <div className="uniform__tabs">
          <button
            className={`uniform__tabs__button ${
              currentIndex === 0 && "uniform__tabs__button--active"
            }`}
            onClick={() => setCurrentIndex(0)}
          >
            Summer Uniform
          </button>
          <button
            className={`uniform__tabs__button ${
              currentIndex === 1 && "uniform__tabs__button--active"
            }`}
            onClick={() => setCurrentIndex(1)}
          >
            Winter Uniform
          </button>
        </div>

        <div>{uniformJSX}</div>
      </div>
    </Layout>
  );
}
